import { getEquipment } from "@services/api/index";
const _token = localStorage.getItem("access_token");


const getEquipmentRedux = (_cnpj) => {
    // OIC EQUIPMENT

    return new Promise((resolve, reject)=>{
      getEquipment(_cnpj, _token)
        .then(equipment => {
          if (equipment?.Propostas?.length > 0) {

            let allModels = equipment.Propostas.map(
              equip => equip.Equipamentos
            ).flat()

            let models = allModels.map(
              equip => equip.Modelo_Equipamento
            );
            let DistinctModels = [...new Set(models)];
  
            resolve({equipment, DistinctModels})
          } else {
            resolve({equipment, DistinctModels: []})
            return null;
          }
        })
        .catch(error => {
          reject(error);
        });
    })
  };

  export default getEquipmentRedux