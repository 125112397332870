import { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  doc: "",
  email: "",
  phone: "",
  responsible: "",
  token: "",
  password: "",
};

const RegisterContext = createContext(initialState);

const registerReducer = (state, action) => {
  switch (action.type) {
    case "SET_DOC":
      return {
        ...state,
        doc: action.payload,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_PHONE":
      return {
        ...state,
        phone: action.payload,
      };
    case "SET_RESPONSIBLE_PERSON":
      return {
        ...state,
        responsible: action.payload,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload,
      };
    case "SET_ENTERPRISE":
      return {
        ...state,
        enterprise: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const RegisterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(registerReducer, initialState, () => {
    const localData = localStorage.getItem("registerState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("registerState", JSON.stringify(state));
  }, [state]);

  const purgeRegisterState = () => {
    localStorage.removeItem("registerState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <RegisterContext.Provider value={{ state, dispatch, purgeRegisterState }}>
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegister = () => {
  const context = useContext(RegisterContext);
  if (!context) {
    throw new Error("useRegister must be used within an RegisterProvider");
  }
  return context;
};
