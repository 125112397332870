import axios from 'axios';

export const revalidateToken = async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    const refreshToken = localStorage.getItem('refresh_token');

    let options = {
      method: 'POST',
      url: process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        refresh_token: refreshToken,
      },
    };

    return new Promise(async (resolve, reject) => {
      return axios
        .request(options)
        .then((res) => {
          const { access_token, token_expiration } = res.data;

          // Date.now() retorna tempo em milissegundos
          const currentDate = new Date();
          // Calcula o tempo de expiração em 8 horas (em milissegundos)
          const expiration_in_milliseconds = 28800;
          currentDate.setSeconds(
            currentDate.getSeconds() + expiration_in_milliseconds
          );

          const token_expiration_seg = currentDate.toLocaleDateString();

          localStorage.setItem('access_token', access_token);
          localStorage.setItem('token_expiration', token_expiration_seg);

          console.log('Token Revalidado!');

          resolve(axios(originalRequest));
        })
        .catch((err) => {
          console.log(
            'Token não pode ser revalidado novamente, necessário refazer o login.'
          );
          reject(err);
        });
    });
  }
};
