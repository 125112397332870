import { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  tickets: [],
  status: 200,
};

const FinanceTicketContext = createContext(initialState);

const financeTicketReducer = (state, action) => {
  switch (action.type) {
    case "SET_FINANCE_TICKET":
      return {
        ...state,
        tickets: action.payload,
      };
    case "SET_STATUS_FINANCE":
      return {
        ...state,
        status: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const FinanceTicketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    financeTicketReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("financeTicketState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("financeTicketState", JSON.stringify(state));
  }, [state]);

  const purgeFinanceTicketState = () => {
    localStorage.removeItem("financeTicketState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <FinanceTicketContext.Provider value={{ state, dispatch, purgeFinanceTicketState }}>
      {children}
    </FinanceTicketContext.Provider>
  );
};

export const useFinanceTicket = () => {
  const context = useContext(FinanceTicketContext);
  if (!context) {
    throw new Error(
      "useFinanceTicket must be used within an FinanceTicketProvider",
    );
  }
  return context;
};
