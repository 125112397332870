import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  condition: false,
  stepNumber: 2,
};

const ConstestTicketStepperContext = createContext(initialState);

const contestTicketStepperReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONDITION":
      return {
        ...state,
        condition: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const ContestTicketStepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contestTicketStepperReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("contestTicketStepperState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("contestTicketStepperState", JSON.stringify(state));
  }, [state]);

  const purgeContestTicketStepperState = () => {
    localStorage.removeItem("contestTicketStepperState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <ConstestTicketStepperContext.Provider
      value={{ state, dispatch, purgeContestTicketStepperState }}
    >
      {children}
    </ConstestTicketStepperContext.Provider>
  );
};

export const useContestTicketStepper = () => {
  const context = useContext(ConstestTicketStepperContext);
  if (!context) {
    throw new Error(
      "useContestTicketStepper must be used within an ContestTicketStepperProvider",
    );
  }
  return context;
};
