import axios from "axios";

export const send_password_to_reset = (_password, _token) => {
  const options = {
    method: 'POST',
    url: process.env.REACT_APP_RESET_PASSWORD,
    headers: {
      Authorization: "Bearer " + _token,
    },
    data: {
      password: _password,
    }
  }

  return new Promise((resolve, reject) => {
    axios(options).then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
  })
}