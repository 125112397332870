import ReactGA from 'react-ga';

export const initializeAnalytics = () => {
  ReactGA.initialize("G-RYK9DRZE23");
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
};