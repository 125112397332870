import axios from "axios";
import sign from 'jwt-encode'

export const _getUserCredentialsToSwap = (_email, _password) => {
  const secret =  process.env.REACT_APP_OCC_PORTAL_SWAP_SECRET + '/login'
  const data = {
    email: _email,
    password: _password
  }

  const _token = sign(data, secret)

  localStorage.setItem('portal_swap', _token)

  var options = {
    method: "GET",
    url: process.env.REACT_APP_OCC_PORTAL_SWAP,
    headers: {
      'Content-Type': 'application/json',
      apikey: `${_token}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  })
}