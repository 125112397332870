import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  devolutionInformation: {
    status: "abe",
    cnpj: "",
    numero_contrato: "",
    propsota: "",
    numero_serie: "",
    numero_interno: "",
    codigo_filial: "",
    tipo_frete: "",
    data_chegada: "",
    hora_chegada: "",
    numero_nf: "",
    nf_base64: null,
    resposta_checklist: {
      "Bateria está carregada?": { resposta: "", observacao: "" },
      "O tanque contém 1/4 de combustível?": {
        resposta: "",
        observacao: "",
      },
      "Local apto a carregamento?": { resposta: "", observacao: "" },
      "Necessita motorista habilitado para o carregamento?": {
        resposta: "",
        observacao: "",
      },
      "Precisa que envie os dados de carregamento com antecedência?": {
        resposta: "",
        observacao: "",
      },
      "Qual antecedência?": { resposta: "", observacao: "" },
      "Há alguma restrição de horário?": { resposta: "", observacao: "" },
      "Há alguma restrição de espaço para o transporte?": {
        resposta: "",
        observacao: "",
      },
    },
  },
  filialEquipmentInformation: {
    openHour: false,
    closeHour: false,
  },
};

const Int009PostSchedulerContext = createContext(initialState);

const int009Reducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYLOAD":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          [Object.keys(action.payload)]:
            action.payload[Object.keys(action.payload)],
        },
      };
    case "SET_FILIAL_OPEN_HOUR":
      return {
        ...state,
        filialEquipmentInformation: {
          ...state.filialEquipmentInformation,
          openHour: action.payload,
        },
      };
    case "SET_FILIAL_CLOSE_HOUR":
      return {
        ...state,
        filialEquipmentInformation: {
          ...state.filialEquipmentInformation,
          closeHour: action.payload,
        },
      };
    case "SET_BATERIA_ESTA_CARREGADA":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Bateria está carregada?": action.payload,
          },
        },
      };
    case "SET_LOCAL_APTO_CARREGAMENTO":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Local apto a carregamento?": action.payload,
          },
        },
      };
    case "SET_TANQUE_COMBUSTIVEL":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "O tanque contém 1/4 de combustível?": action.payload,
          },
        },
      };
    case "SET_NECESSITA_MOTORISTA":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Necessita motorista habilitado para o carregamento?":
              action.payload,
          },
        },
      };
    case "SET_NECESSITA_ANTECEDENCIA":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Precisa que envie os dados de carregamento com antecedência?":
              action.payload,
          },
        },
      };
    case "SET_ANTECEDENCIA":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Qual antecedência?": action.payload,
          },
        },
      };
    case "SET_RESTRICAO_HORARIO":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Há alguma restrição de horário?": action.payload,
          },
        },
      };
    case "SET_RESTRICAO_HORARIO_OBSERVACAO":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Há alguma restrição de horário?": {
              ...state.devolutionInformation.resposta_checklist[
                "Há alguma restrição de horário?"
              ],
              observacao: action.payload,
            },
          },
        },
      };
    case "SET_RESTRICAO_TRANSPORTE":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Há alguma restrição de espaço para o transporte?": action.payload,
          },
        },
      };
    case "SET_RESTRICAO_TRANSPORTE_OBSERVACAO":
      return {
        ...state,
        devolutionInformation: {
          ...state.devolutionInformation,
          resposta_checklist: {
            ...state.devolutionInformation.resposta_checklist,
            "Há alguma restrição de espaço para o transporte?": {
              ...state.devolutionInformation.resposta_checklist[
                "Há alguma restrição de espaço para o transporte?"
              ],
              observacao: action.payload,
            },
          },
        },
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const Int009PostSchedulerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(int009Reducer, initialState, () => {
    const localData = localStorage.getItem("int009PostSchedulerState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("int009PostSchedulerState", JSON.stringify(state));
  }, [state]);

  const purgeInt009State = () => {
    localStorage.removeItem("int009PostSchedulerState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <Int009PostSchedulerContext.Provider value={{ state, dispatch, purgeInt009State }}>
      {children}
    </Int009PostSchedulerContext.Provider>
  );
};

export const useInt009PostScheduler = () => {
  const context = useContext(Int009PostSchedulerContext);
  if (!context) {
    throw new Error(
      "useInt009PostScheduler must be used within an Int009PostSchedulerProvider",
    );
  }
  return context;
};
