import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  tickets: [],
  trackedTicket: {},
  contestFinanceEquipment: {},
  status: 200,
};

const ConstestTicketContext = createContext(initialState);

const contestTicketReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTEST_TICKET":
      return {
        ...state,
        tickets: action.payload,
      };
    case "SET_TRACKED_TICKET":
      return {
        ...state,
        trackedTicket: action.payload,
      };
    case "SET_CONTEST_FINANCE_EQUIPMENT":
      return {
        ...state,
        contestFinanceEquipment: action.payload,
      };
    case "SET_STATUS_CONTEST":
      return {
        ...state,
        status: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const ContestTicketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contestTicketReducer, initialState, () => {
    const localData = localStorage.getItem("contestTicketState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("contestTicketState", JSON.stringify(state));
  }, [state]);

  const purgeContestTicketState = () => {
    localStorage.removeItem("contestTicketState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <ConstestTicketContext.Provider value={{ state, dispatch, purgeContestTicketState }}>
      {children}
    </ConstestTicketContext.Provider>
  );
};

export const useContestTicket = () => {
  const context = useContext(ConstestTicketContext);
  if (!context) {
    throw new Error("useContestTicket must be used within an ContestTicketProvider");
  }
  return context;
};
