import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const getTechnicalTicket = async (cnpj) => {
  let options = {
    method: 'GET',
    url: process.env.REACT_APP_TICKET_INT011 + `/${cnpj}`
  }

  return new Promise((resolve, reject) => {
   _request_KEYCLOAK(options)
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve({});
        }
      })
      .catch(error => {
        reject(error);
      });
  })
}