import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  tabSelected: 0,
};

const TicketTabControlContext = createContext(initialState);

const ticketTabControlReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB":
      return {
        tabSelected: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const TicketTabControlProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    ticketTabControlReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("ticketTabControlState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("ticketTabControlState", JSON.stringify(state));
  }, [state]);

  const purgeTicketTabControlState = () => {
    localStorage.removeItem("ticketTabControlState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <TicketTabControlContext.Provider
      value={{ state, dispatch, purgeTicketTabControlState }}
    >
      {children}
    </TicketTabControlContext.Provider>
  );
};

export const useTicketTabControl = () => {
  const context = useContext(TicketTabControlContext);
  if (!context) {
    throw new Error(
      "useTicketTabControl must be used within an TicketTabControlProvider",
    );
  }
  return context;
};
