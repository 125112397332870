export const americanDatePattern = (date) => {
  if (typeof date !== 'string') return

  return date.split('/').reverse().join('/')
}

export const formatDate = (date, nationality='', separator="-") => {
  let year = new Date(date).getUTCFullYear()
  let month = new Date(date).getUTCMonth()
  let monthAdjust = monthTable(month)
  let day = new Date(date).getUTCDate()

  let monthBeforeTwoDecimal = monthAdjust < 9 ? '0' + monthAdjust.toString() : monthAdjust
  let dayhBeforeTwoDecimal = day < 10 ? '0' + day.toString() : day

  let today = nationality !== 'br' ? `${monthBeforeTwoDecimal}${separator}${dayhBeforeTwoDecimal}${separator}${year}` : `${dayhBeforeTwoDecimal}${separator}${monthBeforeTwoDecimal}${separator}${year}`

  return today
}

export const formatHour = (date) => {
  const HOURS = new Date(date).getUTCHours()
  const MINUTES = new Date(date).getUTCMinutes()

  return `${HOURS}:${MINUTES <= 10 ? '0'.concat(MINUTES) : MINUTES}`
}

export const dateCountdown = (end) => {

  let year = new Date().getFullYear()
  let month = new Date().getMonth()
  let day = new Date().getDate()

  let today = `${day}/${monthTable(month)}/${year}`

  let dateEnd = new Date(americanDatePattern(end))
  let dateInitial = new Date(americanDatePattern(today))
  let diff = dateEnd - dateInitial
  let daysLeft = diff / (1000 * 60 * 60 * 24)

  return daysLeft
}

function monthTable (m) {
  switch(m){
    case 0:
      return 1
      break;
    case 1:
      return 2
    case 2:
      return 3
    case 3:
      return 4
    case 4:
      return 5
    case 5:
      return 6
    case 6:
      return 7
    case 7:
      return 8
    case 8:
      return 9
    case 9:
      return 10
    case 10:
      return 11
    case 11:
      return 12
    default:
      break
  }
}