import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  condition: false,
  stepNumber: 4,
  freight: "Próprio",
};

const SchedulerStepperContext = createContext(initialState);

const schedulerStepperReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONDITION":
      return {
        ...state,
        condition: action.payload,
      };
    case "SET_STEP_NUMBER":
      return {
        ...state,
        stepNumber: action.payload,
      };
    case "SET_FREIGHT":
      return {
        ...state,
        freight: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const SchedulerStepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    schedulerStepperReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("schedulerStepperState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("schedulerStepperState", JSON.stringify(state));
  }, [state]);

  const purgeSchedulerStepperState = () => {
    localStorage.removeItem("schedulerStepperState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <SchedulerStepperContext.Provider
      value={{ state, dispatch, purgeSchedulerStepperState }}
    >
      {children}
    </SchedulerStepperContext.Provider>
  );
};

export const useSchedulerStepper = () => {
  const context = useContext(SchedulerStepperContext);
  if (!context) {
    throw new Error(
      "useSchedulerStepper must be used within an SchedulerStepperProvider",
    );
  }
  return context;
};
