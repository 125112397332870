import axios from "axios";

//TODO fazer reuso do endpoints que foram construidos
export const firstAccessRequest = async _email => {
  let options = {
    method: "POST",
    url: process.env.REACT_APP_FIRST_ACCESS_POST_URL,
    data: {
      email: _email,
    },
  };

 return new Promise((resolve, reject) => {
  axios.request(options)
  .then(res => {
    resolve(res)
  }).catch(err => {
    reject(err)
  })
 })
};
