import React from "react";
import { AccountProvider } from "../_account/_account";
import { FinanceTicketProvider } from "../_finance/_finance_ticket";
import { AlertDialogProvider } from "../_components/_alertDialog";
import { RegisterProvider } from "../_login/_register";
import { DashboardLoginProvider } from "../_login/_dashboardLogin";
import { EquipmentProvider } from "../Equipament/_equipament";
import { ContestTicketProvider } from "../_ticket/contest/_contestTicket";
import { ContestTicketStepperProvider } from "../_ticket/contest/_contestTicketStepper";
import { Int010PostTechTicketProvider } from "../_ticket/technical/_int010_post_technical_ticket";
import { TechnicalTicketCreateProvider } from "../_ticket/technical/_technical_ticket_create";
import { TechnicalTicketProvider } from "../_ticket/technical/_technicalTicket";
import { TechnicalTicketMessageQueueProvider } from "../_ticket/technical/_technicalTicketMessageQueue";
import { TechnicalTicketStepperProvider } from "../_ticket/technical/_technicalTicketStepper";
import { DashboardDevolutionProvider } from "../_ticket/_dashboardDevolution";
import { DashboardPrescheduleProvider } from "../_ticket/_dashboardPreschedule";
import { DevolutionProvider } from "../_ticket/_devolution";
import { SchedulerStepperProvider } from "../_ticket/_scheduler_stepper";
import { TicketTabControlProvider } from "../_ticket/_ticketTabControl";
import { DashboardMenuProvider } from "../config/_dashboardMenu";
import { OCCProvider } from "../occ/_occ";
import { Int009PostSchedulerProvider } from "../_ticket/_int009_post_scheduler";
import { DashboardFilterProvider } from "../_filters/_dashboardFilters";

// Provedor de contexto composto
export const AppProvider = ({ children }) => {
  return (
    <RegisterProvider>
      <DashboardLoginProvider>
        <DashboardMenuProvider>
          <DashboardFilterProvider>
            <OCCProvider>
              <TicketTabControlProvider>
                <DashboardDevolutionProvider>
                  <DashboardPrescheduleProvider>
                    <AccountProvider>
                      <EquipmentProvider>
                        <DevolutionProvider>
                          <Int009PostSchedulerProvider>
                            <TechnicalTicketCreateProvider>
                              <TechnicalTicketStepperProvider>
                                <TechnicalTicketProvider>
                                  <Int010PostTechTicketProvider>
                                    <TechnicalTicketMessageQueueProvider>
                                      <ContestTicketProvider>
                                        <SchedulerStepperProvider>
                                          <ContestTicketStepperProvider>
                                            <FinanceTicketProvider>
                                              <AlertDialogProvider>{children}</AlertDialogProvider>
                                            </FinanceTicketProvider>
                                          </ContestTicketStepperProvider>
                                        </SchedulerStepperProvider>
                                      </ContestTicketProvider>
                                    </TechnicalTicketMessageQueueProvider>
                                  </Int010PostTechTicketProvider>
                                </TechnicalTicketProvider>
                              </TechnicalTicketStepperProvider>
                            </TechnicalTicketCreateProvider>
                          </Int009PostSchedulerProvider>
                        </DevolutionProvider>
                      </EquipmentProvider>
                    </AccountProvider>
                  </DashboardPrescheduleProvider>
                </DashboardDevolutionProvider>
              </TicketTabControlProvider>
            </OCCProvider>
          </DashboardFilterProvider>
        </DashboardMenuProvider>
      </DashboardLoginProvider>
    </RegisterProvider>
  );
};
