import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  menu: false,
  menuProfile: false,
};

const DashboardMenuContext = createContext(initialState);

const dashboardMenuReducer = (state, action) => {
  switch (action.type) {
    case "SET_MENU":
      return {
        ...state,
        menu: action.payload,
      };
    case "SET_MENU_PROFILE":
      return {
        ...state,
        menuProfile: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const DashboardMenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dashboardMenuReducer, initialState, () => {
    const localData = localStorage.getItem("dashboardMenuState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("dashboardMenuState", JSON.stringify(state));
  }, [state]);

  const purgeDashboardMenuState = () => {
    localStorage.removeItem("dashboardMenuState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <DashboardMenuContext.Provider value={{ state, dispatch, purgeDashboardMenuState }}>
      {children}
    </DashboardMenuContext.Provider>
  );
};

export const useDashboardMenu = () => {
  const context = useContext(DashboardMenuContext);
  if (!context) {
    throw new Error("useDashboardMenu must be used within an DashboardMenuProvider");
  }
  return context;
};
