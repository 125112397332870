import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const getEquipment = async (_cnpj, _token) => {
  let options = {
    method: "POST",
    url: process.env.REACT_APP_GET_EQUIPMENT_INT002,
    data: {
      cnpj: _cnpj,
    },
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then(response => {
        if (response?.data) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
