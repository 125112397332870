import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./animatedRoutes";
import { AnimatePresence } from "framer-motion";

function AppRouter() {
  const connected = localStorage.getItem("access_token");
  const isAuthenticated = connected === "" || connected === undefined || connected === null
  
  return (
    <BrowserRouter>
      <AnimatePresence>
        {/* TODO Rotas privadas */}
        <AnimatedRoutes />

        {/* TODO Rotas Publicas */}
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default AppRouter;
