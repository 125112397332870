export const jobConcatOccOiC = async function (proposal, OccFiles, scheduleType) {
  try {
    let equipmentConcat = proposal?.map(item => {
      let occFound;

      if (scheduleType === 'notpreschedule') {
        if (OccFiles.length === 1) {
          occFound = OccFiles[0]?.items.find(occ => {
            return occ?.id === item?.Modelo_Equi?.split(" ").join("-");
          });
        } else {
          occFound = OccFiles.find(occ => {
            return occ?.id === item?.Modelo_Equi?.split(" ").join("-");
          });
        }
      } else {
        if (OccFiles.length >= 1) {
          occFound = OccFiles[0]?.items.find(occ => {
            return occ?.id === item?.Modelo_Equi?.split(" ").join("-");
          });
        } else {
          occFound = OccFiles.find(occ => {
            return occ?.items?.id === item?.Modelo_Equi?.split(" ").join("-");
          });
        }
      }

      if (occFound) {
        return {
          ...item,
          icon: occFound?.x_labelSvg
            ? occFound?.x_labelSvg
            : occFound?.mediumImageURLs?.[0],
          category: occFound?.x_categoria,
          type: occFound?.x_tipo,
        };
      } else {
        return item;
      }
    });

    return Promise.resolve(equipmentConcat);
  } catch (error) {
    return Promise.reject(error);
  }
};
