import axios from 'axios';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    const tokenExpiration = Number(localStorage.getItem('token_expiration'));

    if (token && tokenExpiration && Date.now() < tokenExpiration) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return new Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refresh_token');

      try {
        let options = {
          method: 'POST',
          url: process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          form: {
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
            refresh_token: refreshToken,
          },
        };

        const response = await axios.request(options);

        const { access_token, token_expiration } = response.data;

        // Date.now() retorna tempo em milissegundos
        const currentDate = new Date();
        // Calcula o tempo de expiração em 8 horas (em milissegundos)
        const expiration_in_milliseconds = 28800; // 8 horas
        currentDate.setSeconds(
          currentDate.getSeconds() + expiration_in_milliseconds
        );
        const token_expiration_seg = currentDate.toLocaleDateString();

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('token_expiration', token_expiration_seg);

        return axiosInstance(originalRequest);
      } catch (e) {
        console.error(e);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
