import axios from "axios";

const _request_OCC = async options => {

  let token = window.localStorage.getItem("access_token");

  return new Promise(async (resolve, reject) => {
    options.headers = {};
    options.headers.Authorization = "Bearer " + token;
    axios
      .request(options)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        return error
      });
  });
};

export { _request_OCC };
