import { _request_KEYCLOAK } from "../request/_request_KEYCLOAK";

export const getTicket = async _cnpj => {
  let options = {
    method: "GET",
    url: process.env.REACT_APP_TICKET_INT006 +  `/${_cnpj}`,
  };

  return new Promise((resolve, reject) => {
   _request_KEYCLOAK(options)
      .then(response => {
        if (response?.data) {
          resolve(response.data);
        } else if (response.records){
          resolve(response.records)
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};