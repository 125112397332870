import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const sendContestQuestionary = async ( _body) => {
  let options = {
    method: "POST",
    url: process.env.REACT_APP_TICKET_INT014,
    data: {
      ..._body
    }
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then(response => {
        if (response) {
          resolve(response);
        } else {
          resolve({});
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
