import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const sendFinanceDuplicate = ({
  fatura,
  data_faturamento,
  data_vencimento,
  tipo_documento,
  email_contato,
  nome_contato,
  status,
}) => {
  const options = {
    method: "POST",
    url: process.env.REACT_APP_TICKET_INT013,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      fatura: fatura,
      data_faturamento: data_faturamento,
      data_vencimento: data_vencimento,
      tipo_documento: tipo_documento,
      email_contato: email_contato,
      nome_contato: nome_contato,
      status: status,
    },
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
