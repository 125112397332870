import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const getFinanceProposal = (cnpj) => {
  const options = {
    method: "GET",
    url: process.env.REACT_APP_TICKET_INT012 + `/${cnpj}`,
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
