import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  filter: {
    dropdownFilter: {
      cnpjArray: [],
      estateArray: [],
      fieldFilter: false,
    },
  },
  ticketFilter: { chipFilter: [], fieldFilter: "" },
  data: {
    equip: [],
    ticket: [],
    equipmentNotPreScheduled: [],
    equipmenPreScheduled: [],
  },
  OCCFiles: [],
  EquipModels: [],
};

const DashboardFilterContext = createContext(initialState);

const dashboardFilterReducer = (state, action) => {
  switch (action.type) {
    case "GET_DROPDOWN_FILTER_CNPJ":
      return {
        ...state,
        filter: {
          ...state.filter,
          dropdownFilter: {
            ...state.filter.dropdownFilter,
            cnpjArray: action.payload,
          },
        },
      };
    case "GET_DROPDOWN_FILTER_ESTATE":
      return {
        ...state,
        filter: {
          ...state.filter,
          dropdownFilter: {
            ...state.filter.dropdownFilter,
            estateArray: action.payload,
          },
        },
      };
    case "SET_FIELD_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          dropdownFilter: {
            ...state.filter.dropdownFilter,
            fieldFilter: action.payload,
          },
        },
      };
    case "UPDATE_TICKET_FIELD_FILTER":
      return {
        ...state,
        ticketFilter: {
          ...state.ticketFilter,
          fieldFilter: action.payload,
        },
      };
    case "UPDATE_DROPDOWN_FILTER_CNPJ":
      return {
        ...state,
        filter: {
          ...state.filter,
          dropdownFilter: {
            ...state.filter.dropdownFilter,
            cnpjArray: state.filter.dropdownFilter.cnpjArray.filter(
              (item) => item !== action.payload,
            ),
          },
        },
      };
    case "UPDATE_DROPDOWN_FILTER_ESTATE":
      return {
        ...state,
        filter: {
          ...state.filter,
          dropdownFilter: {
            ...state.filter.dropdownFilter,
            estateArray: state.filter.dropdownFilter.estateArray.filter(
              (item) => item !== action.payload,
            ),
          },
        },
      };
    case "UPDATE_EQUIPMENT":
      return {
        ...state,
        data: {
          ...state.data,
          equip: action.payload,
        },
      };
    case "UPDATE_TICKET":
      return {
        ...state,
        data: {
          ...state.data,
          ticket: action.payload,
        },
      };
    case "UPDATE_EQUIPMENT_NOT_PRE_SCHEDULED":
      return {
        ...state,
        data: {
          ...state.data,
          equipmentNotPreScheduled: Array.isArray(action.payload)
            ? [
                ...state.data.equipmentNotPreScheduled,
                ...action.payload.filter((e) => e !== null),
              ]
            : [...state.data.equipmentNotPreScheduled, action.payload].filter(
                (e) => e !== null,
              ),
        },
      };
    case "UPDATE_EQUIPMENT_PRE_SCHEDULED":
      return {
        ...state,
        data: {
          ...state.data,
          equipmenPreScheduled: action.payload,
        },
      };
    case "UPDATE_OCC_FILES":
      return {
        ...state,
        OCCFiles: action.payload,
      };
    case "UPDATE_EQUIP_MODEL":
      return {
        ...state,
        EquipModels: [...state.EquipModels, action.payload],
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const DashboardFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    dashboardFilterReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("dashboardFilterState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("dashboardFilterState", JSON.stringify(state));
  }, [state]);

  const purgeDashboardFiltersState = () => {
    localStorage.removeItem("dashboardFilterState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <DashboardFilterContext.Provider
      value={{ state, dispatch, purgeDashboardFiltersState }}
    >
      {children}
    </DashboardFilterContext.Provider>
  );
};

export const useDashboardFilter = () => {
  const context = useContext(DashboardFilterContext);
  if (!context) {
    throw new Error(
      "useDashboardFilter must be used within a DashboardFilterProvider",
    );
  }
  return context;
};
