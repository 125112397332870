import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  notPreSchedule: {},
  allNotPrescheduleEquip: [],
};

const DashboardDevolutionContext = createContext(initialState);

const dashboardDevolutionReducer = (state, action) => {
  switch (action.type) {
    case "SET_NOT_PRESCHEDULE":
      return {
        ...state,
        notPreSchedule: action.payload,
      };
    case "SET_ALL_NOT_PRESCHEDULE_EQUIP":
      return {
        ...state,
        allNotPrescheduleEquip: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const DashboardDevolutionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    dashboardDevolutionReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("dashboardDevolutionState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("dashboardDevolutionState", JSON.stringify(state));
  }, [state]);

  const purgeDashboardDevolutionState = () => {
    localStorage.removeItem("dashboardDevolutionState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <DashboardDevolutionContext.Provider
      value={{ state, dispatch, purgeDashboardDevolutionState }}
    >
      {children}
    </DashboardDevolutionContext.Provider>
  );
};

export const useDashboardDevolution = () => {
  const context = useContext(DashboardDevolutionContext);
  if (!context) {
    throw new Error(
      "useDashboardDevolution must be used within an DashboardDevolutionProvider",
    );
  }
  return context;
};
