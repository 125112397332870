import { axiosInstance } from "@helpers/jobs/index.js";
import axios from "axios";

const _request_KEYCLOAK = async options => {
  let token = localStorage.getItem('access_token')

  return new Promise(async (resolve, reject) => {
    options.headers = {};
    options.headers.Authorization = "Bearer " + token;
    axios
      .request(options)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      });
  });
};

export { _request_KEYCLOAK };
