import React, { createContext, useReducer, useContext, useEffect } from "react";
import { useAccount } from "../reducers/_account/_account";
import { useAlertDialog } from "../reducers/_components/_alertDialog";
import { useDashboardFilter } from "../reducers/_filters/_dashboardFilters";
import { useFinanceTicket } from "../reducers/_finance/_finance_ticket";
import { useDashboardLogin } from "../reducers/_login/_dashboardLogin";
import { useRegister } from "../reducers/_login/_register";
import { useContestTicket } from "../reducers/_ticket/contest/_contestTicket";
import { useContestTicketStepper } from "../reducers/_ticket/contest/_contestTicketStepper";
import { useInt010PostTechTicket } from "../reducers/_ticket/technical/_int010_post_technical_ticket";
import { useTechnicalTicketCreate } from "../reducers/_ticket/technical/_technical_ticket_create";
import { useTechnicalTicket } from "../reducers/_ticket/technical/_technicalTicket";
import { useTechnicalTicketMessageQueue } from "../reducers/_ticket/technical/_technicalTicketMessageQueue";
import { useTechnicalTicketStepper } from "../reducers/_ticket/technical/_technicalTicketStepper";
import { useDashboardDevolution } from "../reducers/_ticket/_dashboardDevolution";
import { useDashboardPreschedule } from "../reducers/_ticket/_dashboardPreschedule";
import { useDevolution } from "../reducers/_ticket/_devolution";
import { useInt009PostScheduler } from "../reducers/_ticket/_int009_post_scheduler";
import { useSchedulerStepper } from "../reducers/_ticket/_scheduler_stepper";
import { useTicketTabControl } from "../reducers/_ticket/_ticketTabControl";
import { useDashboardMenu } from "../reducers/config/_dashboardMenu";
import { useEquipment } from "../reducers/Equipament/_equipament";
import { useOCC } from "../reducers/occ/_occ";

const ResetContext = createContext();

export const ResetProvider = ({ children }) => {
  const { purgeAccountState } = useAccount();
  const { purgeAlertDialogState } = useAlertDialog();
  const { purgeDashboardFiltersState } = useDashboardFilter();
  const { purgeFinanceTicketState } = useFinanceTicket();
  const { purgeDashboardLoginState } = useDashboardLogin();
  const { purgeRegisterState } = useRegister();
  const { purgeContestTicketState } = useContestTicket();
  const { purgeContestTicketStepperState } = useContestTicketStepper();
  const { purgeInt010PostTechTicketState } = useInt010PostTechTicket();
  const { purgeTechTicketCreateState } = useTechnicalTicketCreate();
  const { purgeTechTicketState } = useTechnicalTicket();
  const { purgeTechnicalTicketMessageQueueState } =
    useTechnicalTicketMessageQueue();
  const { purgeTechnicalTicketStepperState } = useTechnicalTicketStepper();
  const { purgeDashboardDevolutionState } = useDashboardDevolution();
  const { purgeDashboardPrescheduleState } = useDashboardPreschedule();
  const { purgeDevolutionState } = useDevolution();
  const { purgeInt009State } = useInt009PostScheduler();
  const { purgeSchedulerStepperState } = useSchedulerStepper();
  const { purgeTicketTabControlState } = useTicketTabControl();
  const { purgeDashboardMenuState } = useDashboardMenu();
  const { purgeEquipmentState } = useEquipment();
  const { purgeOccState } = useOCC();

  const purge = (contexts = []) => {
    if (contexts.length < 1) {
      purgeAccountState();
      purgeAlertDialogState();
      purgeDashboardFiltersState();
      purgeFinanceTicketState();
      purgeDashboardLoginState();
      purgeRegisterState();
      purgeContestTicketState();
      purgeContestTicketStepperState();
      purgeInt010PostTechTicketState();
      purgeTechTicketCreateState();
      purgeTechTicketState();
      purgeTechnicalTicketMessageQueueState();
      purgeTechnicalTicketStepperState();
      purgeDashboardDevolutionState();
      purgeDashboardPrescheduleState();
      purgeDevolutionState();
      purgeInt009State();
      purgeSchedulerStepperState();
      purgeTicketTabControlState();
      purgeDashboardMenuState();
      purgeEquipmentState();
      purgeOccState();
    }
    if (contexts.includes("account")) {
      purgeAccountState();
    }
    if (contexts.includes("alertDialog")) {
      purgeAlertDialogState();
    }
    if (contexts.includes("dashboardFilters")) {
      purgeDashboardFiltersState();
    }
    if (contexts.includes("financeTicket")) {
      purgeFinanceTicketState();
    }
    if (contexts.includes("dashboardLogin")) {
      purgeDashboardLoginState();
    }
    if (contexts.includes("register")) {
      purgeRegisterState();
    }
    if (contexts.includes("contestTicket")) {
      purgeContestTicketState();
    }
    if (contexts.includes("contestTicketStepper")) {
      purgeContestTicketStepperState();
    }
    if (contexts.includes("int010PostTechTicket")) {
      purgeInt010PostTechTicketState();
    }
    if (contexts.includes("technicalTicketCreate")) {
      purgeTechTicketCreateState();
    }
    if (contexts.includes("technicalTicket")) {
      purgeTechTicketState();
    }
    if (contexts.includes("technicalTicketMessageQueue")) {
      purgeTechnicalTicketMessageQueueState();
    }
    if (contexts.includes("technicalTicketStepper")) {
      purgeTechnicalTicketStepperState();
    }
    if (contexts.includes("dashboardDevolution")) {
      purgeDashboardDevolutionState();
    }
    if (contexts.includes("dashboardPreschedule")) {
      purgeDashboardPrescheduleState();
    }
    if (contexts.includes("devolution")) {
      purgeDevolutionState();
    }
    if (contexts.includes("int009PostScheduler")) {
      purgeInt009State();
    }
    if (contexts.includes("schedulerStepper")) {
      purgeSchedulerStepperState();
    }
    if (contexts.includes("ticketTabControl")) {
      purgeTicketTabControlState();
    }
    if (contexts.includes("dashboardMenu")) {
      purgeDashboardMenuState();
    }
    if (contexts.includes("equipment")) {
      purgeEquipmentState();
    }
    if (contexts.includes("occ")) {
      purgeOccState();
    }
  };

  return (
    <ResetContext.Provider value={{ purge }}>{children}</ResetContext.Provider>
  );
};

export const useReset = () => {
  const context = useContext(ResetContext);
  if (!context) {
    throw new Error("useReset must be used within an ResetProvider");
  }
  return context;
};
