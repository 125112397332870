import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const sendDeliveryCopy = async ({
  no_nfe,
  codigo_filial,
  email_contato,
  nome_contato,
  status,
}) => {
  let options = {
    method: "POST",
    url: process.env.REACT_APP_TICKET_INT003,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      no_nfe,
      codigo_filial,
      email_contato,
      nome_contato,
      status,
    },
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
