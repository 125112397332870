import { createContext, useContext, useReducer, useEffect } from "react";

export const initialState = {
  active: false,
};

const AlertDialogContext = createContext(initialState);

const alertDialogReducer = (state, action) => {
  switch (action.type) {
    case "SET_DIALOG":
      return {
        active: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const AlertDialogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertDialogReducer, initialState, () => {
    const localData = localStorage.getItem("alertDialogState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("alertDialogState", JSON.stringify(state));
  }, [state]);

  const purgeAlertDialogState = () => {
    localStorage.removeItem("alertDialogState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <AlertDialogContext.Provider
      value={{ state, dispatch, purgeAlertDialogState }}
    >
      {children}
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => {
  const context = useContext(AlertDialogContext);
  if (!context) {
    throw new Error(
      "useAlertDialog must be used within an AlertDialogProvider",
    );
  }
  return context;
};
