import { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  equip: [],
  ticket: [],
};

const EquipmentContext = createContext(initialState);

const equipmentReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_EQUIPMENT":
      return {
        ...state,
        equip: action.payload,
      };
    case "UPDATE_TICKET":
      return {
        ...state,
        ticket: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const EquipmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(equipmentReducer, initialState, () => {
    const localData = localStorage.getItem("equipmentState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("equipmentState", JSON.stringify(state));
  }, [state]);

  const purgeEquipmentState = () => {
    localStorage.removeItem("equipmentState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <EquipmentContext.Provider value={{ state, dispatch, purgeEquipmentState }}>
      {children}
    </EquipmentContext.Provider>
  );
};

export const useEquipment = () => {
  const context = useContext(EquipmentContext);
  if (!context) {
    throw new Error("useEquipment must be used within an EquipmentProvider");
  }
  return context;
};
