import { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  connected: false,
  tokenLogin: null,
};

const DashboardLoginContext = createContext(initialState);

const dashboardLoginReducer = (state, action) => {
  switch (action.type) {
    case "GET_CONNECTED":
      window.localStorage.setItem("isConnected", action.payload);
      return {
        ...state,
        connected: action.payload,
      };
    case "SET_TOKEN_LOGIN":
      return {
        ...state,
        tokenLogin: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const DashboardLoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    dashboardLoginReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("dashboardLoginState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("dashboardLoginState", JSON.stringify(state));
  }, [state]);

  const purgeDashboardLoginState = () => {
    localStorage.removeItem("dashboardLoginState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <DashboardLoginContext.Provider value={{ state, dispatch, purgeDashboardLoginState }}>
      {children}
    </DashboardLoginContext.Provider>
  );
};

export const useDashboardLogin = () => {
  const context = useContext(DashboardLoginContext);
  if (!context) {
    throw new Error(
      "useDashboardLogin must be used within an DashboardLoginProvider",
    );
  }
  return context;
};
