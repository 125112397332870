import { lazy } from "react";

const delayOnImport = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const DELAY = 2000

//Private
const loadDashboard = lazy(() => import("@containers/Dashboard"))
const loadDashboardEquipment = lazy(() => import ("@containers/Dashboard/Equipment/DashboardEquipment"))
const loadFinanceHome = lazy(()=> import("@containers/Dashboard/Finance/FinanceHome"))
const loadFinanceDuplicate = lazy(() => import ("@containers/Dashboard/Finance/FinanceDupicate"))
const loadOpenNewContest = lazy(() => import ("@containers/Dashboard/Ticket/Contest/OpenNewContest"))
const loadStepperPage = lazy(() => import ("@containers/Dashboard/Ticket/Contest/StepperPage"))
const loadDashboardFavoriteCardHome = lazy(() => import("@containers/Dashboard/components/DashboardFavoriteCardHome"))
const loadTicketHome = lazy(() => import("@containers/Dashboard/Ticket/TicketHome"))
const loadCallingDeiveryCopy = lazy(() => import("@containers/Dashboard/Ticket/Devolution/DeiveryCopy/DeiveryDuplicate"))
const loadPrescheduleDevolutionContainer = lazy(() => import("@containers/Dashboard/Ticket/Devolution/Preschedule/PrescheduleDevolutionContainer"))
const loadCallingSchedule = lazy(() => import("@containers/Dashboard/Ticket/Devolution/Schedule/TicketSchedule"))
const loadTrackPage = lazy(()=> import("@containers/Dashboard/Ticket/Technical/TrackPage/TrackPage"))
const loadContestTrackPage = lazy(()=> import("@containers/Dashboard/Ticket/Contest/TrackPage"))
const loadTechnicalTicketStepsContainer = lazy(() => import("@containers/Dashboard/Ticket/Technical/TechnicalTicketStepsConntainer/TechnicalTicketStepsContainer"))
const loadTechnicalTicketOpenNewTicket = lazy(() => import("@containers/Dashboard/Ticket/Technical/TechnicalTicketStepsConntainer/TechnicalTicketOpenNewTicket"))
const loadNotFound = lazy(() => import("@containers/NotFound"))

//Public
const loadSignIn = lazy(() => import("@containers/Login/Sign-in/index"))
const loadHomeCreateAccount = lazy(() => import("@containers/Login/Register/index"))
const loadLogin = lazy(() => import("@containers/Login/index"))
const loadPasswordRecover = lazy(() => import("@containers/Login/Recover/index"))
const loadRecoverSuccess = lazy(() => import("@containers/Login/Recover/components/recoverSuccess"))
const loadRegister = lazy(() => import("@containers/Login/Register/Register"))
const loadReset = lazy(() => import("@containers/Login/Reset/index"))
const loadEnterResetStep = lazy(() => import("@containers/Login/Reset/components/EnterResetStep"))
const loadResetSuccess = lazy(() => import("@containers/Login/Reset/components/ResetSuccess"))

const loadSuspensePage = lazy(() => import("@layout/Suspense/SuspensePage"))

export const ROUTES = {
  PRIVATE: {
    loadDashboard: loadDashboard,
    loadDashboardEquipment: loadDashboardEquipment,
    loadFinanceHome: loadFinanceHome,
    loadFinanceDuplicate: loadFinanceDuplicate,
    loadOpenNewContest: loadOpenNewContest,
    loadStepperPage: loadStepperPage,
    loadDashboardFavoriteCardHome: loadDashboardFavoriteCardHome,
    loadTicketHome: loadTicketHome,
    loadCallingDeiveryCopy: loadCallingDeiveryCopy,
    loadPrescheduleDevolutionContainer: loadPrescheduleDevolutionContainer,
    loadCallingSchedule: loadCallingSchedule,
    loadTrackPage: loadTrackPage,
    loadContestTrackPage: loadContestTrackPage,
    loadTechnicalTicketStepsContainer: loadTechnicalTicketStepsContainer,
    loadTechnicalTicketOpenNewTicket: loadTechnicalTicketOpenNewTicket,
    loadNotFound: loadNotFound
  },
  PUBLIC: {
    loadSignIn,
    loadHomeCreateAccount,
    loadLogin,
    loadPasswordRecover,
    loadRecoverSuccess,
    loadRegister,
    loadReset,
    loadEnterResetStep,
    loadResetSuccess,
    loadSuspensePage
  }
}
