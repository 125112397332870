import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  queueCondition: 0,
};

const TechnicalTicketMessageQueueContext = createContext(initialState);

const technicalTicketMessageQueueReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUEUE_CONDITION":
      return {
        queueCondition: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const TechnicalTicketMessageQueueProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    technicalTicketMessageQueueReducer,
    initialState,
    () => {
      const localData = localStorage.getItem(
        "technicalTicketMessageQueueState",
      );
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem(
      "technicalTicketMessageQueueState",
      JSON.stringify(state),
    );
  }, [state]);

  const purgeTechnicalTicketMessageQueueState = () => {
    localStorage.removeItem("technicalTicketMessageQueueState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <TechnicalTicketMessageQueueContext.Provider
      value={{ state, dispatch, purgeTechnicalTicketMessageQueueState }}
    >
      {children}
    </TechnicalTicketMessageQueueContext.Provider>
  );
};

export const useTechnicalTicketMessageQueue = () => {
  const context = useContext(TechnicalTicketMessageQueueContext);
  if (!context) {
    throw new Error(
      "useTechnicalTicketMessageQueue must be used within an TechnicalTicketMessageQueueProvider",
    );
  }
  return context;
};
