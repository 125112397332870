import { getTicket } from "@services/api/index";
const _token = localStorage.getItem("access_token");

const getTicketRedux = _cnpj => {
  // OIC TICKET
  return new Promise((resolve, reject) => {
    getTicket(_cnpj, _token)
      .then(ticket => {
        resolve(ticket);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default getTicketRedux