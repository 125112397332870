import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  OCCFiles: [],
  EquipModels: [],
};

const OCCContext = createContext(initialState);

const occReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_OCC_FILES":
      return {
        ...state,
        OCCFiles: action.payload,
      };
    case "UPDATE_EQUIP_MODEL":
      return {
        ...state,
        EquipModels: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const OCCProvider = ({ children }) => {
  const [state, dispatch] = useReducer(occReducer, initialState, () => {
    const localData = localStorage.getItem("occState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("occState", JSON.stringify(state));
  }, [state]);

  const purgeOccState = () => {
    localStorage.removeItem("occState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <OCCContext.Provider value={{ state, dispatch, purgeOccState }}>
      {children}
    </OCCContext.Provider>
  );
};

export const useOCC = () => {
  const context = useContext(OCCContext);
  if (!context) {
    throw new Error(
      "useOCC must be used within an OCCProvider",
    );
  }
  return context;
};
