import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  condition: false,
  stepNumber: 5,
};

const TechnicalTicketStepperContext = createContext(initialState);

const technicalTicketStepperReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONDITION":
      return {
        ...state,
        condition: action.payload,
      };
    case "SET_STEP_NUMBER":
      return {
        ...state,
        stepNumber: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const TechnicalTicketStepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    technicalTicketStepperReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("technicalTicketStepperState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("technicalTicketStepperState", JSON.stringify(state));
  }, [state]);

  const purgeTechnicalTicketStepperState = () => {
    localStorage.removeItem("technicalTicketStepperState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <TechnicalTicketStepperContext.Provider
      value={{ state, dispatch, purgeTechnicalTicketStepperState }}
    >
      {children}
    </TechnicalTicketStepperContext.Provider>
  );
};

export const useTechnicalTicketStepper = () => {
  const context = useContext(TechnicalTicketStepperContext);
  if (!context) {
    throw new Error(
      "useTechnicalTicketStepper must be used within an TechnicalTicketStepperProvider",
    );
  }
  return context;
};
