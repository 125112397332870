import axios from "axios";

export const  send_email_to_forgot_password = async _email => {
  const options = {
    method: 'POST',
    url: process.env.REACT_APP_EMAIL_FORGOT_PASSWORD,
    data: {
      email: _email
    }
  }

  return new Promise((resolve, reject) => {
    axios(options).then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
  })
};
