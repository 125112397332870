import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  cnpj: false,
  fullName: false,
  email: false,
  phone: false,
  id: false,
  numberOfVisits: false,
  lastVisitDate: false,
};

const AccountContext = createContext(initialState);

const accountReducer = (state, action) => {
  switch (action.type) {
    case "SET_ACCOUNT":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const AccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState, () => {
    const localData = localStorage.getItem("accountState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("accountState", JSON.stringify(state));
  }, [state]);

  const purgeAccountState = () => {
    localStorage.removeItem("accountState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <AccountContext.Provider value={{ state, dispatch, purgeAccountState }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
