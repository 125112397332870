import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  equipmentNotPreScheduled: [],
  equipmentPreScheduled: [],
  proposalSugar: [],
  proposalCnpj: "",
};

const DevolutionContext = createContext(initialState);

const devolutionReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_EQUIPMENT_NOT_PRE_SCHEDULED":
      return {
        ...state,
        equipmentNotPreScheduled: action.payload,
      };
    case "UPDATE_EQUIPMENT_PRE_SCHEDULED":
      return {
        ...state,
        equipmentPreScheduled: action.payload,
      };
    case "SET_PROPOSAL_SUGAR":
      return {
        ...state,
        proposalSugar: action.payload,
      };
    case "SET_PROPOSAL_CNPJ":
      return {
        ...state,
        proposalCnpj: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const DevolutionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(devolutionReducer, initialState, () => {
    const localData = localStorage.getItem("devolutionState");
    return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem("devolutionState", JSON.stringify(state));
  }, [state]);

  const purgeDevolutionState = () => {
    localStorage.removeItem("devolutionState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <DevolutionContext.Provider
      value={{ state, dispatch, purgeDevolutionState }}
    >
      {children}
    </DevolutionContext.Provider>
  );
};

export const useDevolution = () => {
  const context = useContext(DevolutionContext);
  if (!context) {
    throw new Error("useDevolution must be used within an DevolutionProvider");
  }
  return context;
};
