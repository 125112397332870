import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import AppRouter from "./router/router";
import { throttle, debounce } from "./helpers";
import { AppProvider } from "./assets/js/reducers/AppProvider/AppProvider";
import { ResetProvider } from "./assets/js/store/resetContext";

initializeAnalytics();

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { initializeAnalytics } from "./_services/analytics/googleAnalytics";

// event params - ms
const LIMIT = 500;
const DELAY = 300;

// event listeners
window.addEventListener(
  "scroll",
  throttle(() => {
    console.log(`requested from server after ${LIMIT} ms - scroll`);
  }, LIMIT),
);

window.addEventListener(
  "input",
  debounce(() => {
    console.log(`requested from server after ${DELAY} ms - typing`);
  }, DELAY),
);

// versão
let versionLocal = window.localStorage.getItem("version");

//Se sim a variavel que voce tem no storage e igual a minha
if (!versionLocal || versionLocal !== process.env.REACT_APP_VERSION) {
  window.localStorage.clear();
  window.localStorage.setItem("version", process.env.REACT_APP_VERSION);
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#ffa151",
      main: "#f37021",
      dark: "#C24003",
      contrastText: "#fff",
    },
    secondary: {
      light: "#366b6d",
      main: "#004042",
      dark: "#001b1b",
      contrastText: "#000",
    },
    danger: {
      main: "#B00020",
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: "IBM_Plex",
    button: {
      textTransform: "none",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppProvider>
    <ResetProvider>
      <ThemeProvider theme={theme}>
        <Suspense>
          <SnackbarProvider
            maxSnack={1}
            dense={true}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            preventDuplicate
          >
            <AppRouter />
          </SnackbarProvider>
        </Suspense>
      </ThemeProvider>
    </ResetProvider>
  </AppProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//TODO Remover imports sem uso
//TODO Testar componentes que receberam componentes semelhantes
//TODO remover ação de checagem de token dos componentes
//TODO Otimizar componentes reutilizando
//TODO Criar popup de messagem para Erros de CRUD, Advertencias e Notificações de Ação
//TODO criar um array de resposta padrão para ser aplicado nos popups
//TODO
