import moment from 'moment';

function jobHourGenerator(startTime, endTime) {
  if (moment(endTime, 'HH:mm') < moment(startTime, 'HH:mm')) return;

  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const options = [];

  while (start.isSameOrBefore(end)) {
    options.push(start.format('HH:mm'));
    start.add(30, 'minutes');
  }

  return options;
}

export default jobHourGenerator;
