import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const createUser = (cnpj, email) => {
  const options = {
    method: "POST",
    url: process.env.REACT_APP_TICKET_INT016,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      cnpj: cnpj,
      email: email,
    },
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
