import { getEquipmentsOcc } from "@services/api_occ/index";

const _token = localStorage.getItem("access_token");

const getEquipmentsOccRedux = (model) => {
    return new Promise((resolve, reject) => {
      getEquipmentsOcc(model, _token)
        .then(({data}) => {
          resolve(data)
        })
        .catch(error => {
          reject(error);
        });
    })
  };

  export default getEquipmentsOccRedux