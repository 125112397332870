import { getEquipmentPreScheduled } from "../_services/api";
import { getEquipmentsOccRedux } from "@services/api_occ/index";
import { jobConcatOccOiC } from "@helpers/jobs/jobConcatOccOiC.js";

const getPrescheduleEquipment = async (cnpj) => {
  try {
    const responses = await Promise.all([getEquipmentPreScheduled(cnpj)]);
    const newTicketEquipmentPreSchedule = responses[0]?.records;

    const newOccFiles = await Promise.all(
      newTicketEquipmentPreSchedule?.map(async (equipament) => {
        return await getEquipmentsOccRedux(equipament?.Modelo_Equi);
      }),
    );

    const concatEquipResponse = await jobConcatOccOiC(
      newTicketEquipmentPreSchedule,
      newOccFiles,
      "preschedule",
    );

    return concatEquipResponse
  } catch (error) {
    console.log(error);
  }
};

export default getPrescheduleEquipment