import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  tickets: [],
  trackedTicket: {},
  status: 200,
};

const TechnicalTicketContext = createContext(initialState);

const technicalTicketReducer = (state, action) => {
  switch (action.type) {
    case "SET_TECHNICAL_TICKET":
      return {
        ...state,
        tickets: action.payload,
      };
    case "SET_TRACKED_TICKET":
      return {
        ...state,
        trackedTicket: action.payload,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const TechnicalTicketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    technicalTicketReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("technicalTicketState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("technicalTicketState", JSON.stringify(state));
  }, [state]);

  const purgeTechTicketState = () => {
    localStorage.removeItem("technicalTicketState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <TechnicalTicketContext.Provider value={{ state, dispatch, purgeTechTicketState }}>
      {children}
    </TechnicalTicketContext.Provider>
  );
};

export const useTechnicalTicket = () => {
  const context = useContext(TechnicalTicketContext);
  if (!context) {
    throw new Error(
      "useTechnicalTicket must be used within an TechnicalTicketProvider",
    );
  }
  return context;
};
