
import { _request_OCC } from "@services/request/_request_OCC";

export const getEquipmentsOcc = async (_cod, _token) => {

  var options = {
    method: "GET",
    url: process.env.REACT_APP_GET_OCC_EQUIPMENTS
  };

  return new Promise((resolve,reject)=>{
    _request_OCC(options).then(response=>{
      resolve(response)
    }).catch(error=>{
      reject(error)
    })
  })

}