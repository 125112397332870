import { lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { ROUTES } from './constants/routeList'

//Private
const Dashboard = ROUTES.PRIVATE.loadDashboard
const DashboardEquipment = ROUTES.PRIVATE.loadDashboardEquipment
const FinanceHome = ROUTES.PRIVATE.loadFinanceHome
const FinanceDuplicate = ROUTES.PRIVATE.loadFinanceDuplicate
const OpenNewContest = ROUTES.PRIVATE.loadOpenNewContest
const StepperPage = ROUTES.PRIVATE.loadStepperPage
const DashboardFavoriteCardHome = ROUTES.PRIVATE.loadDashboardFavoriteCardHome
const TicketHome = ROUTES.PRIVATE.loadTicketHome
const CallingDeiveryCopy = ROUTES.PRIVATE.loadCallingDeiveryCopy
const PrescheduleDevolutionContainer = ROUTES.PRIVATE.loadPrescheduleDevolutionContainer
const CallingSchedule = ROUTES.PRIVATE.loadCallingSchedule
const TrackPage = ROUTES.PRIVATE.loadTrackPage
const ContestTrackPage = ROUTES.PRIVATE.loadContestTrackPage
const TechnicalTicketStepsContainer = ROUTES.PRIVATE.loadTechnicalTicketStepsContainer
const TechnicalTicketOpenNewTicket = ROUTES.PRIVATE.loadTechnicalTicketOpenNewTicket
const NotFound = ROUTES.PRIVATE.loadNotFound

//Public
const SignIn = ROUTES.PUBLIC.loadSignIn
const HomeCreateAccount = ROUTES.PUBLIC.loadHomeCreateAccount
const Login = ROUTES.PUBLIC.loadLogin
const PasswordRecover = ROUTES.PUBLIC.loadPasswordRecover
const RecoverSuccess = ROUTES.PUBLIC.loadRecoverSuccess
const Register = ROUTES.PUBLIC.loadRegister
const Reset = ROUTES.PUBLIC.loadReset
const EnterResetStep = ROUTES.PUBLIC.loadEnterResetStep
const ResetSuccess = ROUTES.PUBLIC.loadReset

const SuspensePage = lazy(() => import("@layout/Suspense/SuspensePage"))

const ProtectedRouteDashboard = ({ children }) => {
  const connected = localStorage.getItem("access_token");

  if (connected === "" || connected === undefined || connected === null)
    return <Navigate to="/" />;

  return children;
};

const ProtectedHome = ({ children }) => {
  const connected = localStorage.getItem("access_token");
  if (!!connected) return <Navigate to="/Painel-de-controle" />;

  return children;
};

function AnimatedRoutes() {
  const path = useLocation();
  const dashboardPath = '/Painel-de-controle'
  return (
    <Suspense fallback={<SuspensePage />}>
      <Routes location={path}>
        <Route
          path="/"
          element={
            <ProtectedHome>
              <Login />
            </ProtectedHome>
          }
        >
          <Route
            index
            element={<HomeCreateAccount />}
          />

          <Route
            path="/Cadastro"
            element={<Register />}
          />

          <Route
            path="/Entrar"
            element={<SignIn />}
          />

        </Route>

        <Route
          path="/Trocar-senha"
          element={<Reset />} 
        >
          <Route 
            index
            element={<EnterResetStep />} 
          />

          <Route 
            path="/Trocar-senha/Sucesso"
            element={<ResetSuccess />} 
          />
        </Route>

        <Route
          path={dashboardPath}
          element={
            <ProtectedRouteDashboard>
              <Dashboard />
            </ProtectedRouteDashboard>
          }
        >
          <Route index element={<DashboardFavoriteCardHome />} />
          <Route
            path={dashboardPath + "/Equipamentos"}
            element={<DashboardEquipment />}
          />
          <Route
            path={dashboardPath + "/Pre-Agendamento/Abertura-Devolucao"}
            element={<PrescheduleDevolutionContainer />}
          />
          <Route path={dashboardPath + "/Chamados"} element={<TicketHome />}>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path={dashboardPath + "/Chamados/Abertura-Chamado"} element={<TechnicalTicketStepsContainer />} />
          <Route path={dashboardPath + "/Chamados/Novo-Chamado"} element={<TechnicalTicketOpenNewTicket />} />
          <Route path={dashboardPath + "/Chamados/Abrir-Contestacao"} element={<OpenNewContest />} />
          <Route path={dashboardPath + "/Chamados/Nova-Contestacao"} element={<StepperPage />} />
          <Route path={dashboardPath + "/Chamados/Contestacao/Acompanhar"} element={<ContestTrackPage />} />
          <Route path={dashboardPath + "/Chamados/Acompanhar"} element={<TrackPage />} />
          <Route
            path={dashboardPath + "/Agendamento/Proposta"}
            element={<CallingSchedule />}
          />
          <Route
            path={dashboardPath + "/Agendamento/Proposta/Segunda-via-nota-de-remessa/:id"}
            element={<CallingDeiveryCopy />}
          />

          <Route
            path={dashboardPath + "/Financeiro"}
            element={<FinanceHome />}
          />
          <Route
            path={dashboardPath + '/Financeiro/Segunda-via/:id'}
            element={<FinanceDuplicate />}
          />
        </Route>


        <Route path="/Recuperacao-de-conta" element={<PasswordRecover />} />
        <Route
          path="/Recuperacao-de-conta/sucesso"
          element={<RecoverSuccess />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AnimatedRoutes;
