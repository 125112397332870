export const cnpj_mask = (cnpj, isCensored=false) => {
  if (isCensored) {
    let firstPartial = cnpj.slice(0, 2);
    let secondPartial = cnpj.slice(2, 5).replaceAll(/[0-9]/g, "*");
    let thirdPartial = cnpj.slice(5, 8).replaceAll(/[0-9]/g, "*");
    let fouthPartial = cnpj.slice(8, cnpj.length - 2).replaceAll(/[0-9]/g, "*");
    let fifthPartial = cnpj.slice(cnpj.length - 2, cnpj.length);
  
    return (
      firstPartial +
      "." +
      secondPartial +
      "." +
      thirdPartial +
      "/" +
      fouthPartial +
      "-" +
      fifthPartial
    );
  } else {
    let firstPartial = cnpj.slice(0, 2);
    let secondPartial = cnpj.slice(2, 5);
    let thirdPartial = cnpj.slice(5, 8);
    let fouthPartial = cnpj.slice(8, cnpj.length - 2);
    let fifthPartial = cnpj.slice(cnpj.length - 2, cnpj.length);

    return (
      firstPartial +
      "." +
      secondPartial +
      "." +
      thirdPartial +
      "/" +
      fouthPartial +
      "-" +
      fifthPartial
    );

  }

};
