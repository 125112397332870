import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const cancelPreschedule = async (idSugar) => {
  let options = {
    method: "POST",
    url: process.env.REACT_APP_TICKET_INT009_1 + `/${idSugar}`,
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((response) => {
        if (response) {
          resolve(response);
        } else {
          resolve({});
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
